import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslation } from 'localization';
import { Profile, Transaction } from 'types';

import { fixDecimal } from 'utils';

import CopyClipBoard from '../CopyClipBoard';
import TransactionMethod from '../TransactionMethod';
import TransactionStatus from '../TransactionStatus';
import XmAdditionalData from '../XmAdditionalData';

type TransactionDetailModalProps = {
  transaction: Transaction;
  profile: Profile;
  isOpen: boolean;
  onClose: () => void;
};

const TransactionDetailModal: React.FC<TransactionDetailModalProps> = ({
  transaction,
  profile,
  isOpen,
  onClose,
}: TransactionDetailModalProps) => {
  const { t } = useTranslation('component.dashboard.recentTransactions.modal');

  const trType = transaction.transaction_method.type;
  const isDestination =
    typeof transaction.destination_wallet === 'object' &&
    transaction.destination_wallet !== null;
  const isCrypto = ['DEPOSIT_CRYPTO', 'WITHDRAW_CRYPTO', 'SWAP'].includes(
    transaction.transaction_method.category
  );

  const amountTr = isDestination
    ? {
        amount: transaction.destination_amount,
        symbol: transaction.destination_wallet.currency.symbol,
      }
    : {
        amount: transaction.amount,
        symbol: transaction.source_wallet.currency.symbol,
      };
  const swapDetail =
    trType === 'SWAP'
      ? {
          source_amount: fixDecimal({
            value: transaction.amount,
            fractionDigits: isCrypto ? 5 : 2,
          }),
          source_symbol: transaction.source_wallet.currency.symbol,
          destination_amount: fixDecimal({
            value: transaction.destination_amount,
            fractionDigits: isCrypto ? 5 : 2,
          }),
          destination_symbol: transaction.destination_wallet.currency.symbol,
        }
      : {};
  const transferDetail =
    trType === 'TRANSFER'
      ? isDestination
        ? {
            text: t('fromTransferField'),
            user: transaction.form.transfer.source_identifier_number,
          }
        : {
            text: t('toTransferField'),
            user: transaction.form.transfer.destination_identifier_number,
          }
      : {};
  const cryptoDetail = isCrypto
    ? trType === 'DEPOSIT'
      ? {
          text: t('fromTxId'),
          id: transaction.form.deposit_crypto?.id,
          txId: transaction.form.deposit_crypto?.txid,
        }
      : {
          text: t('toTxId'),
          id: transaction.form.withdraw_crypto?.id,
          txId: transaction.form.withdraw_crypto?.txid,
        }
    : {};

  const isLDTr = ['DEPOSIT_LD', 'WITHDRAW_LD'].includes(
    transaction.transaction_method.category
  );
  const isBrokerTr = ['DEPOSIT_BROKER', 'WITHDRAW_BROKER'].includes(
    transaction.transaction_method.category
  );

  const isIntermediary =
    (profile?.role.title === 'LD' && isLDTr) ||
    (profile?.role.title === 'BROKER' && isBrokerTr);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('title')}</ModalHeader>
        <ModalCloseButton right={4} left="unset" />
        <ModalBody>
          <Flex flexDir="column" gap={4} pb={4}>
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <TransactionMethod
                  type={trType}
                  isIntermediary={isIntermediary}
                />
                <Text>
                  {trType === 'DEPOSIT'
                    ? isIntermediary
                      ? 'Withdraw'
                      : 'Deposit'
                    : trType === 'WITHDRAW'
                      ? isIntermediary
                        ? 'Deposit'
                        : 'Withdraw'
                      : trType === 'TRANSFER'
                        ? 'Transfer'
                        : trType === 'SWAP'
                          ? 'Exchange'
                          : ''}
                </Text>
              </Flex>
              <TransactionStatus status={transaction.status} />
            </Flex>
            <Divider orientation="horizontal" />
            <Flex align="center" justify="space-between">
              <Text color="body2">{t('status')}</Text>
              <Text fontSize="md" color="body">
                {['UNAUTHORIZED', 'NOT_CLEARED', 'DENIED', 'CANCELED'].includes(
                  transaction.status
                )
                  ? 'Failed'
                  : ['SETTLED'].includes(transaction.status)
                    ? 'Success'
                    : 'Pending'}
              </Text>
            </Flex>
            {trType !== 'SWAP' && (
              <Flex align="center" justify="space-between">
                <Text color="body2">{t('amountFrom')}</Text>
                <Text fontSize="md" color="body">
                  {`${
                    fixDecimal({
                      value: amountTr.amount,
                      fractionDigits: isCrypto ? 5 : 2,
                    }) || '-'
                  } ${amountTr.symbol}`}
                </Text>
              </Flex>
            )}
            {trType === 'SWAP' && (
              <Flex align="center" justify="space-between">
                <Text color="body2">{t('amountFrom')}</Text>
                <Text fontSize="md" color="body">
                  {`${swapDetail.source_amount || '-'} ${
                    swapDetail.source_symbol
                  }`}
                </Text>
              </Flex>
            )}
            {trType === 'SWAP' && (
              <Flex align="center" justify="space-between">
                <Text color="body2">{t('amountTo')}</Text>
                <Text fontSize="md" color="body">
                  {`${swapDetail.destination_amount || '-'} ${
                    swapDetail.destination_symbol
                  }`}
                </Text>
              </Flex>
            )}
            {trType === 'TRANSFER' && (
              <Flex align="center" justify="space-between">
                <Text color="body2">{transferDetail.text}</Text>
                <Text fontSize="md" color="body">
                  {transferDetail.user || '-'}
                </Text>
              </Flex>
            )}
            {trType !== 'SWAP' && (
              <Flex align="center" justify="space-between">
                <Text color="body2">{t('trFee')}</Text>
                <Text fontSize="md" color="body">
                  {transaction.fee.fee_amount
                    ? fixDecimal({
                        value: transaction.fee.fee_amount,
                        fractionDigits: isCrypto ? 5 : 2,
                      }) + amountTr.symbol
                    : '0'}
                </Text>
              </Flex>
            )}
            <Flex align="center" justify="space-between">
              <Text color="body2">{t('dateTime')}</Text>
              <Text fontSize="md" color="body">
                {dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm:ss')}
              </Text>
            </Flex>
            {isCrypto &&
              (cryptoDetail.id || cryptoDetail.txId) &&
              cryptoDetail.id && (
                <Flex>
                  <Text color="body2">{t('internalID')}</Text>
                  <Text fontSize="md" color="body">
                    <CopyClipBoard
                      initialValue={cryptoDetail.id || ''}
                      showCharacterLength={16}
                    />
                  </Text>
                </Flex>
              )}
            {isCrypto &&
              (cryptoDetail.id || cryptoDetail.txId) &&
              cryptoDetail.txId && (
                <Flex>
                  <Text color="body2">{t('txID')}</Text>
                  <Text fontSize="md" color="body">
                    <CopyClipBoard
                      initialValue={cryptoDetail.txId || ''}
                      showCharacterLength={16}
                    />
                  </Text>
                </Flex>
              )}
          </Flex>
          {profile.system_identifier === 'XM' &&
            ['DEPOSIT_BROKER:XM', 'WITHDRAW_BROKER:XM'].includes(
              transaction.transaction_method.key
            ) && <XmAdditionalData transaction={transaction} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default TransactionDetailModal;
