import React from 'react';
import { Box, Divider, Text, Tooltip } from '@chakra-ui/react';
import { ReactComponent as IsPrimaryIcon } from 'assets/icons/successIcon.svg';
import {
  useAccess,
  useContacts,
  useDeleteContact,
  usePrimaryContact,
} from 'hooks';
import { useTranslation } from 'localization';
import { Contacts, Profile } from 'types';

import { Button, Spinner } from 'components';

import {
  AccountDetail,
  BorderedBox,
  CreateContact,
  DeleteAccount,
  PersonalDetail,
} from './components';

const ProfileTab: React.FC<{ profile: Profile | undefined }> = ({
  profile,
}) => {
  const access = useAccess();
  const { t } = useTranslation('component.settings.profile');
  const {
    data: contacts,
    isLoading: contactsLoading,
    refetch: refreshContacts,
  } = useContacts();
  const { mutateAsync: deleteContact, isLoading: deleteLoading } =
    useDeleteContact();
  const { mutateAsync: primaryContact, isLoading: primaryLoading } =
    usePrimaryContact();

  const account: {
    label: string;
    value: any;
    isEditable?: boolean;
    isHide?: boolean;
    isCopyable: boolean;
  }[] = [
    {
      label: t('userId'),
      value: profile?.identifier_number,
      isHide: profile && ['LD', 'BROKER'].includes(profile?.role.title),
      isCopyable: true,
    },
    {
      label: t('userEmail'),
      value: profile?.email,
      isHide: false,
      isCopyable: false,
    },
    {
      label: t('userPassword'),
      value: '********',
      isEditable: true,
      isHide: false,
      isCopyable: false,
    },
    {
      label: t('userNickname'),
      value: profile?.username,
      isHide: false,
      isCopyable: false,
    },
  ];
  const personal = [
    { label: t('firstName'), value: profile?.profile.first_name },
    { label: t('lastName'), value: profile?.profile.last_name },
  ];

  const ContactTypeList: React.FC<{
    type: 'MOBILE' | 'EMAIL';
    list: Contacts;
  }> = ({ type, list }) => {
    return (
      <Box w={{ base: '100%', xl: '50%' }}>
        <Box
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="lg" color="body2">
            {type} :
          </Text>
          <CreateContact
            buttonTitle={`Add ${type === 'MOBILE' ? 'Mobile' : 'Email'}`}
            type={type}
            onSuccess={refreshContacts}
          />
        </Box>
        {list.map((contact) => {
          return (
            <React.Fragment key={contact.id}>
              <Box
                display="flex"
                flexDir={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                justifyContent={{ base: 'center', md: 'flex-start' }}
                py={2}
                minH={{ base: '91px', md: '64px' }}
              >
                <Box display="flex" alignItems="center">
                  <Text
                    fontSize="lg"
                    mr={3}
                    textTransform="lowercase"
                    wordBreak="break-all"
                  >
                    {contact.identity}
                  </Text>
                  {contact.is_primary && (
                    <Tooltip label={t('primaryContact')} placement="top">
                      <Box w={{ base: '16px', md: '40px' }}>
                        <IsPrimaryIcon width="100%" />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Box
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    {contact.status === 'NOT_VERIFIED' && (
                      <Text fontSize="xs" mr={3} color="warning">
                        {t('notVerified')}
                      </Text>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center">
                    {contact.status === 'VERIFIED' && !contact.is_primary && (
                      <Button
                        fontSize="xs"
                        variant="ghost"
                        title={t('setPrimary')}
                        p={2}
                        isLoading={primaryLoading}
                        onClick={() =>
                          primaryContact({ id: contact.id }).then(() => {
                            refreshContacts();
                          })
                        }
                      />
                    )}
                    {contact.status === 'NOT_VERIFIED' && (
                      <CreateContact
                        buttonTitle={t('resend')}
                        type={type}
                        verifyId={contact.id}
                        onSuccess={refreshContacts}
                      />
                    )}
                    {!contact.is_primary && (
                      <Button
                        fontSize="xs"
                        variant="ghost"
                        color="warniimport { ReactComponent as Edit } from 'assets/icons/edit.svg';
                        ng"
                        title={t('delete')}
                        p={2}
                        onClick={() =>
                          deleteContact({ id: contact.id || '' }).then(() => {
                            refreshContacts();
                          })
                        }
                        isLoading={deleteLoading}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      w="100%"
      bgColor="dark"
      my={8}
      p={4}
      display="flex"
      flexDir="column"
      gap={4}
    >
      <Box
        w="100%"
        display="flex"
        flexDir={{ base: 'column', xl: 'row' }}
        alignItems="flex-start"
        justifyContent="space-between"
        gap={4}
      >
        <AccountDetail title={t('accSetting')} list={account} />
        <PersonalDetail title={t('personalInfo')} list={personal} />
      </Box>
      <BorderedBox title={t('yourContact')} w="100%">
        {contactsLoading && (
          <Box
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner />
          </Box>
        )}
        {contacts && !contactsLoading && (
          <Box
            w="100%"
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            flexDir={{ base: 'column', xl: 'row' }}
            gap={4}
          >
            <ContactTypeList
              list={
                contacts?.list.filter((item) => item.type === 'EMAIL') || []
              }
              type="EMAIL"
            />
            <ContactTypeList
              list={
                contacts?.list.filter((item) => item.type === 'MOBILE') || []
              }
              type="MOBILE"
            />
          </Box>
        )}
      </BorderedBox>
      {access.meDeletePermission && <DeleteAccount />}
    </Box>
  );
};

export default ProfileTab;
