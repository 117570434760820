import { Box, Image, VStack } from '@chakra-ui/react';
import bannerDesktop from 'assets/images/banner-desktop.jpg';
import bannerMobile from 'assets/images/banner-mobile.jpg';
import { useProfile } from 'hooks';

import { Spinner } from 'components';

import { UserDashboard } from './components';

const Dashboard = () => {
  const { data: profile, isLoading } = useProfile();

  return (
    <VStack w="100%" gap="4" mt="4" px={{ base: '4', md: 0 }}>
      <Image
        display={{ base: 'none', xl: 'block' }}
        src={bannerDesktop}
        h={{ base: '92px', md: '165px' }}
        w="full"
        borderRadius="lg"
        objectFit="cover"
      />
      <Image
        display={{ base: 'block', xl: 'none' }}
        src={bannerMobile}
        h={{ base: '92px', md: '165px' }}
        w="full"
        borderRadius="lg"
        objectFit="cover"
      />
      {isLoading && (
        <Box py={10}>
          <Spinner />
        </Box>
      )}
      {!isLoading && profile && <UserDashboard profile={profile} />}
    </VStack>
  );
};
export default Dashboard;
