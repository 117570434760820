import { useNavigate } from 'react-router-dom';
import { Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { useAccess, useProfile } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Button } from 'components';

const QuickAccess = () => {
  const { t } = useTranslation('component.dashboard.quickAccess');
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const access = useAccess();

  return (
    <Stack
      direction={{ base: 'column', xl: 'row' }}
      bg="dark"
      w="full"
      borderRadius="lg"
      justify="space-between"
      p={{ base: '4', xl: '6' }}
      gap="0"
    >
      <Text
        fontSize={{ base: 'md', lg: '26px' }}
        fontWeight={{ base: 'medium', lg: 'semibold' }}
        mb={{ base: '4', lg: 0 }}
      >
        {t('title')}
      </Text>
      <Grid
        templateColumns={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(2, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
        templateRows={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          lg: 'unset',
        }}
        pt={{ base: 0, lg: 10, xl: 0 }}
        textAlign={'center'}
        rowGap={{ base: '4', lg: '14' }}
        columnGap={{ base: '14', lg: '14' }}
      >
        <GridItem>
          <Button
            title={t('deposit')}
            variant="outline"
            bg="transparent"
            minW={100}
            maxW={100}
            color="primary"
            _hover={{ color: 'body' }}
            onClick={() => navigate(routes.deposit.url)}
          />
        </GridItem>
        {access.withdrawService && (
          <GridItem>
            <Button
              title={t('withdraw')}
              variant="outline"
              bg="transparent"
              minW={100}
              maxW={100}
              color="error"
              _hover={{ color: 'body' }}
              onClick={() => navigate(routes.withdraw.url)}
            />
          </GridItem>
        )}
        <GridItem>
          <Button
            title={t('exchange')}
            variant="outline"
            bg="transparent"
            minW={100}
            maxW={100}
            color="secondary2"
            _hover={{ color: 'body' }}
            onClick={() => navigate(routes.exchange.url)}
          />
        </GridItem>
        {access.transferService &&
          !['LD', 'BROKER'].includes(profile?.role.title || '') && (
            <GridItem>
              <Button
                title={t('transfer')}
                variant="outline"
                bg="transparent"
                minW={100}
                maxW={100}
                color="secondary2"
                _hover={{ color: 'body' }}
                onClick={() => navigate(routes.transfer.url)}
              />
            </GridItem>
          )}
      </Grid>
    </Stack>
  );
};
export default QuickAccess;
