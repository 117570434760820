import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { ReactComponent as DownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ExchangeIcon } from 'assets/icons/exchange-filled.svg';
import { useTranslation } from 'localization';

import { Button } from 'components';
import { fixDecimal } from 'utils';

type ApproveModalType = {
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
  data: {
    to: string;
    from: string;
    rate: number;
    amount: number;
    destination_amount: number;
  };
  onButton: () => void;
};

const ApproveModal: React.FC<ApproveModalType> = ({
  onClose,
  isOpen,
  loading,
  data,
  onButton,
}: ApproveModalType) => {
  const { t } = useTranslation('component.exchange.modal');

  return (
    <Box display="flex" alignItems="center">
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalBody py={10} px={4} overflowY="auto">
            <VStack align="center" justify="flex-start" h="100%">
              <VStack w="100%" align="center" justify="center" flex="auto">
                <ExchangeIcon />
                <Text fontSize="4xl" fontWeight="500" color="gray" py={2}>
                  {`${data.amount} ${data.from}`}
                </Text>
                <DownIcon />
                <Text fontSize="4xl" fontWeight="500">
                  {`${fixDecimal({
                    value: data.destination_amount,
                    fractionDigits: 5,
                  })} ${data.to}`}
                </Text>
              </VStack>
              <TableContainer w="100%" overflowX="auto">
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td textAlign="left">{t('from')}</Td>
                      <Td textAlign="right">{data.from}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="left">{t('to')}</Td>
                      <Td textAlign="right">{data.to}</Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="left">{t('rate')}</Td>
                      <Td textAlign="right">{`1 ${data.from} = ${data.rate} ${data.to}`}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Button
                w="100%"
                variant="filled"
                title={t('confirm')}
                onClick={() => {
                  onButton();
                  onClose();
                }}
                isLoading={loading}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ApproveModal;
