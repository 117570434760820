import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';

const BorderedBox: React.FC<
  {
    children: React.ReactNode;
    title: string;
    titleProps?: TextProps;
  } & BoxProps
> = ({
  title,
  children,
  titleProps,
  ...props
}: {
  title: string;
  children: React.ReactNode;
  titleProps?: TextProps;
} & BoxProps) => {
  return (
    <Box
      borderRadius={10}
      border="1px solid grey"
      p={4}
      w={{ base: '100%', xl: '50%' }}
      {...props}
    >
      <Text fontSize="xl" pb={4} {...titleProps}>
        {title}
      </Text>
      {children}
    </Box>
  );
};
export default BorderedBox;
