import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDeleteAccount, useLocalStorage } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import BorderedBox from '../BorderedBox';

const DeleteAccount: React.FC = () => {
  const { t } = useTranslation('component.settings.deleteAccount');
  const { setItem } = useLocalStorage();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: delAcc } = useDeleteAccount();

  const deleteAccount = () => {
    delAcc({}).then(() => {
      setItem('magix-token', '');
      onClose();
      navigate(routes.auth.signUp.url);
    });
  };

  return (
    <BorderedBox
      borderColor="error"
      title={t('title')}
      w="100%"
      titleProps={{ color: 'error' }}
    >
      <Flex gap={4} flexDir="column">
        <Text>{t('description')}</Text>
        <Button w="max-content" size="sm" colorScheme="red" onClick={onOpen}>
          {t('deleteYourAccount')}
        </Button>
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent mx={4} bgColor="dark">
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              {t('modal.title')}
            </Text>
          </ModalHeader>
          <ModalCloseButton right={4} left="unset" />
          <ModalBody px={4} overflowY="auto">
            <Text>{t('modal.description')}</Text>
            <Flex gap={4}>
              <Button
                w="max-content"
                size="sm"
                colorScheme="red"
                onClick={() => deleteAccount()}
                my={4}
              >
                {t('modal.deleteYourAccount')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BorderedBox>
  );
};

export default DeleteAccount;
