import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { url } from 'statics';
import type { ErrorResponse, ListResponse, Pair, PairsPayload } from 'types';

const usePairs = ({
  enabled,
  query,
}: {
  enabled: boolean;
  query?: PairsPayload | undefined;
}): UseQueryResult<ListResponse<Pair>, ErrorResponse> => {
  const q = new URLSearchParams(query).toString();

  return useQuery([`${url.pairs}${q ? '?' + q : ''}`], {
    enabled,
  });
};
export default usePairs;
