import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { useUpdatePassword } from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';

import { Button, CopyClipBoard, Input, PasswordStrength } from 'components';
import { passwordRegex, yup } from 'utils';

import BorderedBox from '../BorderedBox';

type PayloadType = { old_password: string; password: string };

const AccountDetail: React.FC<{
  title: string;
  list: {
    label: string;
    value: any;
    isEditable?: boolean;
    isHide?: boolean;
    isCopyable: boolean;
  }[];
}> = ({ title, list }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('component.settings.profile.updatePassword');

  const { mutateAsync: changePassword } = useUpdatePassword();

  const schema = yup.object().shape({
    old_password: yup.string().label(t('form.old_password')).required(),
    password: yup
      .string()
      .label(t('form.password'))
      .matches(passwordRegex)
      .required(),
    confirm_password: yup
      .string()
      .label(t('form.confirm_password'))
      .oneOf([yup.ref('password')], t('form.same_password'))
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = async (data: PayloadType & { confirm_password: string }) => {
    changePassword({
      password: data.password,
      old_password: data.old_password,
    }).then(() => {
      onClose();
      reset();
    });
  };

  const formProps = useInputAttributes(
    register,
    errors,
    {
      old_password: t('form.old_password'),
      password: t('form.password'),
      confirm_password: t('form.confirm_password'),
    },
    ['password']
  );

  return (
    <BorderedBox title={title}>
      <Box>
        {list
          .filter((item) => !item.isHide)
          .map((item) => {
            return (
              <Stack
                key={item.label}
                w="100%"
                alignItems={{ base: 'flex-start', md: 'center' }}
                justifyContent="space-between"
                flexDir="row"
                py={2}
              >
                <Stack
                  gap={2}
                  flexDir={{ base: 'column', md: 'row' }}
                  align={{ base: 'flex-start', md: 'center' }}
                >
                  <Text fontSize="lg" color="body2" pr={2}>
                    {item.label} :
                  </Text>
                  {item.isCopyable ? (
                    <CopyClipBoard
                      initialValue={item.value?.toString() || ''}
                    />
                  ) : (
                    <Text wordBreak="break-all" fontSize="lg">
                      {item.value}
                    </Text>
                  )}
                </Stack>
                {item.isEditable && (
                  <Box cursor="pointer" onClick={onOpen}>
                    <Edit />
                  </Box>
                )}
              </Stack>
            );
          })}
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('form.title')}</ModalHeader>
          <ModalCloseButton right={4} left="unset" />
          <ModalBody p={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={6}>
                <Input type="password" {...formProps.old_password} />
              </Box>
              <Box mb={6}>
                <Input type="password" {...formProps.password} />
              </Box>
              <Box>
                <Input type="password" {...formProps.confirm_password} />
              </Box>
              <PasswordStrength password={watch('password')} />
              <Button
                // isLoading={loading}
                type="submit"
                variant="filled"
                display="block"
                title={t('form.submit')}
                minW="100%"
                mt={{ base: 8, md: 8 }}
              />
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BorderedBox>
  );
};

export default AccountDetail;
