import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';
import { ErrorResponse } from 'types';

import { api } from 'utils';

const useDeleteAccount = (): UseMutationResult<any, ErrorResponse> => {
  return useMutation(() => api.delete(url.deleteAccount));
};
export default useDeleteAccount;
