const CLIENTS = '/client';
const AUTH = `${CLIENTS}/auth`;
const ME = `${CLIENTS}/me`;
const CURRENCIES = `${CLIENTS}/currencies`;
const WALLETS = `${CLIENTS}/wallets`;
const TRANSACTIONS = `${CLIENTS}/transactions`;
const TRANSACTION_METHODS = `${CLIENTS}/transaction-methods`;
const TRANSFER = `${CLIENTS}/transfers`;
const BANK_ACCOUNTS = `${CLIENTS}/bank-accounts`;
const DEPOSITS = `${CLIENTS}/deposits`;
const WITHDRAWS = `${CLIENTS}/withdraws`;
const LD_PROPOSAL = `${CLIENTS}/ld-proposal`;
const RATE = `${CLIENTS}/rates`;
const SWAP = `${CLIENTS}/swaps`;
const WHITE_LIST = `${CLIENTS}/whitelist`;
const CONTACTS = `${CLIENTS}/contacts`;
const SESSIONS = `${CLIENTS}/sessions`;
const EXTERNAL_USERS = `${CLIENTS}/external-users`;
const FEES = `${CLIENTS}/fees`;
const COUNTRIES = `${CLIENTS}/countries`;
const PAIRS = `${CLIENTS}/pairs`;

const url = {
  auth: AUTH,
  sendEmail: `${AUTH}/send-registration-email`,
  generateSignUpToken: `${AUTH}/generate-registration-token`,
  sendPassword: `${AUTH}/register`,
  initiateLogin: `${AUTH}/initiate-login`,
  resendCode: `${AUTH}/resend-login-code`,
  verification: `${AUTH}/login`,
  forgotPassword: `${AUTH}/send-forget-password-email`,
  generateForgotPasswordToken: `${AUTH}/generate-forget-password-token`,
  newPassword: `${AUTH}/forget-password`,
  updatePassword: `${AUTH}/update-password`,
  logout: `${AUTH}/logout`,
  contacts: CONTACTS,
  createContact: CONTACTS,
  verifyContact: CONTACTS,
  contactSendOtp: `${CONTACTS}`,
  deleteContact: `${CONTACTS}`,
  primaryContact: `${CONTACTS}`,
  kycLevel1: `${ME}/upgrade-to-level-1`,
  kycLevel2: `${ME}/upgrade-to-level-2`,
  balance: `${ME}/balance`,
  profile: ME,
  sessions: SESSIONS,
  deleteSession: SESSIONS,
  wallets: WALLETS,
  wallet: WALLETS,
  transactions: TRANSACTIONS,
  transactionMethods: TRANSACTION_METHODS,
  transfer: `${TRANSFER}/user`,
  bankAccounts: BANK_ACCOUNTS,
  createBankAccount: BANK_ACCOUNTS,
  depositBankTransfer: `${DEPOSITS}/bank-transfer`,
  withdrawBankTransfer: `${WITHDRAWS}/bank-transfer`,
  withdrawBankTransferApprove: `${WITHDRAWS}/bank-transfer`,
  approveTransaction: `${TRANSFER}/user`,
  exchange: `${SWAP}/swap`,
  approveExchange: `${SWAP}/swap`,
  swapAvailableSource: `${SWAP}/available-source-wallets`,
  swapAvailableDestination: `${SWAP}/available-destination-wallets`,
  rate: `${RATE}/provide`,
  cryptoWhitelist: `${WHITE_LIST}/crypto-withdrawal-accounts`,
  createCryptoWhitelist: `${WHITE_LIST}/crypto-withdrawal-accounts`,
  createCryptoWhitelistOtp: `${WHITE_LIST}/send-otp`,
  withdrawCrypto: `${WITHDRAWS}/crypto`,
  withdrawCryptoApprove: `${WITHDRAWS}/crypto`,
  depositLdUsers: `${DEPOSITS}/ld-users`,
  withdrawLdUsers: `${WITHDRAWS}/ld-users`,
  initDepositProposal: LD_PROPOSAL,
  initWithdrawProposal: LD_PROPOSAL,
  proposalList: LD_PROPOSAL,
  proposalRate: LD_PROPOSAL,
  rejectProposal: LD_PROPOSAL,
  settleProposal: LD_PROPOSAL,
  proposalBankAccount: LD_PROPOSAL,
  proposalCashPayment: LD_PROPOSAL,
  approveProposalByUser: LD_PROPOSAL,
  cancelProposalByUser: LD_PROPOSAL,
  uploadDepositBankReceipt: `${DEPOSITS}/ld`,
  uploadWithdrawBankReceipt: `${WITHDRAWS}/ld`,
  lDWithdrawConfirm: `${WITHDRAWS}/ld`,
  approveLDTransaction: `${DEPOSITS}/ld`,
  depositLDApprove: `${DEPOSITS}/ld`,
  withdrawLDApprove: `${WITHDRAWS}/ld`,
  withdrawLDSettle: `${WITHDRAWS}/ld`,
  currencies: CURRENCIES,
  depositRequests: `${DEPOSITS}/ld-requests`,
  withdrawRequests: `${WITHDRAWS}/ld-requests`,
  withdrawSendOtp: `${WITHDRAWS}/send-otp`,
  ldWithdrawResendOtp: `${WITHDRAWS}/ld-resend-otp`,
  externalUserReject: EXTERNAL_USERS,
  externalUserVerify: EXTERNAL_USERS,
  serviceTransaction: `${WITHDRAWS}/broker`,
  sendCodeServiceTransaction: `${WITHDRAWS}/broker-send-otp`,
  verifyServiceWithdraw: `${WITHDRAWS}/broker`,
  cancelServiceWithdraw: `${WITHDRAWS}/broker`,
  verifyServiceDeposit: `${DEPOSITS}/broker`,
  cancelServiceDeposit: `${DEPOSITS}/broker`,
  fee: FEES,
  feeCalculation: FEES,
  country: COUNTRIES,
  pairs: PAIRS,
  deleteAccount: ME,
};
export default url;
