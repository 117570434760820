import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Card, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useExchange,
  useExchangeApprove,
  usePairs,
  useRate,
  useWalletWithCurrency,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { ExchangeResponse, TransactionMethodKey } from 'types';

import { Button, Input, PercentSelect } from 'components';
import { fixDecimal, yup } from 'utils';

import ApproveModal from '../ApproveModal';
import TradingView from '../TradingView';

import { ExchangeTypeButton } from './components';

type PayloadType = {
  source_wallet: string;
  amount: number;
};

const AdvanceExchange: React.FC = () => {
  const { t } = useTranslation('component.exchange.advance');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [type, setType] = React.useState<'sell' | 'buy'>('buy');

  const [exchangeDetail, setExchangeDetail] =
    React.useState<ExchangeResponse | null>(null);

  const { data: pairs, isLoading: isLoadingPairs } = usePairs({
    enabled: true,
  });

  const {
    data: rate,
    mutateAsync: getRate,
    isLoading: isLoadingRate,
  } = useRate();

  const pairsList = pairs
    ? pairs?.list
        ?.filter((item) => item.transaction_methods?.[0]?.key === 'SWAP:MARKET')
        .map((pair) => ({
          label: pair.title,
          value: pair.id,
        }))
    : [];

  const { mutateAsync: submit, isLoading: submitLoading } = useExchange();
  const { mutateAsync: approve, isLoading: approveLoading } =
    useExchangeApprove();

  const formConfig = {
    schema: yup.object().shape({
      source_wallet: yup.string().label(t('form.source_wallet')).required(),
      amount: yup.number().label(t('form.amount')).required(),
    }),
    items: {
      source_wallet: t('form.source_wallet'),
      amount: t('form.amount'),
    },
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    reset,
    resetField,
  } = useForm({
    resolver: yupResolver(formConfig.schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, formConfig.items);

  const selectedPair = pairs?.list.find(
    (pair) => pair.id === watch('source_wallet')
  );

  const { data: firstWallets, remove: removeFirstWallet } =
    useWalletWithCurrency({
      currency_id: selectedPair?.base_currency.id || '',
    });
  const { data: secondWallets, remove: removeSecondWallets } =
    useWalletWithCurrency({
      currency_id: selectedPair?.quote_currency.id || '',
    });

  const firstPairWallet = firstWallets?.list[0] || undefined;
  const secondPairWallet = secondWallets?.list[0] || undefined;

  React.useEffect(() => {
    if (pairsList && pairsList?.length > 0) {
      setValue('source_wallet', pairsList[0].value);
    }
  }, [isLoadingPairs]);

  React.useEffect(() => {
    if (selectedPair) {
      getRate({
        source_symbol: selectedPair?.base_currency.symbol || '',
        destination_symbol: selectedPair?.quote_currency.symbol || '',
        transaction_method_key: selectedPair?.transaction_methods?.[0]
          .key as TransactionMethodKey,
      });
    }
  }, [selectedPair]);

  const exchangeType = {
    buy: {
      buttonStyle: {
        title: t('buy'),
        color: 'primary',
      },
      source: secondPairWallet,
      destination: firstPairWallet,
      inSourceRate: 1,
      inDestinationRate: rate?.rate
        ? fixDecimal({ value: rate?.rate || 0, fractionDigits: 5 })
        : '-',
      destinationAmount: parseFloat(
        fixDecimal({
          value: watch('amount') / (rate?.rate || 0),
          fractionDigits: 5,
        })
      ),
    },
    sell: {
      buttonStyle: {
        title: t('sell'),
        color: 'error',
      },
      source: firstPairWallet,
      destination: secondPairWallet,
      inSourceRate: rate?.rate
        ? fixDecimal({ value: rate?.rate || 0, fractionDigits: 5 })
        : '-',
      inDestinationRate: 1,
      destinationAmount: parseFloat(
        fixDecimal({
          value: watch('amount') * (rate?.rate || 0),
          fractionDigits: 5,
        })
      ),
    },
  };

  const onSubmit = async (data: PayloadType) => {
    if (rate) {
      submit({
        amount: data.amount,
        source_wallet: exchangeType[type].source?.id || '',
        destination_wallet: exchangeType[type].destination?.id || '',
        destination_amount: exchangeType[type].destinationAmount,
        rate: rate?.rate,
      }).then((res) => {
        if (res.id) {
          setExchangeDetail(res);
          onOpen();
        }
      });
    }
  };

  React.useEffect(() => {
    if (watch('source_wallet')) {
      resetField('amount', undefined);
    }
  }, [watch('source_wallet')]);

  return (
    <Stack pt={4} flexDir={{ base: 'column', xl: 'row' }}>
      <Card w={{ base: '100%', xl: 300 }} px={2} py={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={4}>
            <ExchangeTypeButton
              toggle={(value) => {
                setType(value);
                resetField('amount', undefined);
              }}
              type={type}
            />
            <Input
              {...formProps.source_wallet}
              isSelectBox
              placeholder=""
              options={pairsList || []}
              isLoading={isLoadingPairs}
            />
            <Input
              label="Price"
              value={
                selectedPair && rate?.rate
                  ? // eslint-disable-next-line max-len
                    `1 ${exchangeType[type].source?.currency?.symbol || ''} = ${type === 'buy' ? fixDecimal({ value: 1 / rate.rate, fractionDigits: 5 }) : fixDecimal({ value: rate.rate, fractionDigits: 5 })} ${exchangeType[type].destination?.currency?.symbol || ''}`
                  : ''
              }
              isDisabled
              color="primary"
              textAlign="center"
              isLoading={isLoadingRate}
            />
            <Input
              {...formProps.amount}
              type="number"
              step="0.00001"
              rightValue={exchangeType[type].source?.currency.symbol}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              w="100%"
            >
              <PercentSelect
                list={[25, 50, 75, 100]}
                onPercent={(item) =>
                  setValue(
                    'amount',
                    parseFloat(
                      fixDecimal({
                        value:
                          ((exchangeType[type].source?.amount || 0) * item) /
                          100,
                        fractionDigits: 5,
                      })
                    )
                  )
                }
              />
            </Box>
            <Stack py={4}>
              <Stack flexDir="row" justify="space-between">
                <Text>{t('available')}</Text>
                <Text fontWeight="bold">
                  {selectedPair
                    ? (fixDecimal({
                        value: exchangeType[type].source?.amount || 0,
                        fractionDigits: 5,
                      }) || 0) +
                      ' ' +
                      exchangeType[type].source?.currency?.symbol
                    : '-'}
                </Text>
              </Stack>
              <Stack flexDir="row" justify="space-between">
                <Text>
                  {t('estimated') +
                    ' ' +
                    exchangeType[type].destination?.currency?.symbol || ''}
                </Text>
                <Text fontWeight="bold">
                  {`${
                    watch('amount')
                      ? rate?.rate &&
                        fixDecimal({
                          value: exchangeType[type].destinationAmount,
                          fractionDigits: 5,
                        })
                      : 0
                  } ${exchangeType[type].destination?.currency?.symbol || ''}`}
                </Text>
              </Stack>
            </Stack>
            <Button
              type="submit"
              title={exchangeType[type].buttonStyle.title}
              bgColor={exchangeType[type].buttonStyle.color}
              isDisabled={
                watch('amount') > (exchangeType[type].source?.amount || 0)
              }
              isLoading={submitLoading}
            />
          </Stack>
        </form>
      </Card>
      <Stack flex={{ base: 'unset', xl: 1 }} h={{ base: '600px', xl: 'unset' }}>
        {selectedPair?.title && (
          <TradingView currency={selectedPair?.title.replace('-', '')} />
        )}
      </Stack>
      <ApproveModal
        data={{
          amount: exchangeDetail?.amount || 0,
          destination_amount: exchangeDetail?.destination_amount || 0,
          from: exchangeType[type].source?.currency.symbol || '',
          to: exchangeType[type].destination?.currency.symbol || '',
          rate: parseFloat(
            fixDecimal({ value: rate?.rate || 1, fractionDigits: 5 })
          ),
        }}
        onClose={onClose}
        isOpen={isOpen}
        loading={approveLoading}
        onButton={() => {
          approve({ id: exchangeDetail?.id || '' }).then(() => {
            setExchangeDetail(null);
            removeFirstWallet();
            removeSecondWallets();
            reset();
          });
        }}
      />
    </Stack>
  );
};

export default AdvanceExchange;
