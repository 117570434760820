import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useExchange,
  useExchangeApprove,
  usePairs,
  useRate,
  useSwapAvailableDestinations,
  useSwapAvailableSources,
} from 'hooks';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { ExchangeResponse, TransactionMethodKey, Wallet } from 'types';

import { Button, Input, PercentSelect, Spinner } from 'components';
import { fixDecimal, yup } from 'utils';

import ApproveModal from '../ApproveModal';

type PayloadType = {
  source_wallet: string;
  destination_wallet: string;
  amount: number;
};

const SimpleExchange: React.FC = () => {
  const { t } = useTranslation('component.exchange.simple');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const decimal: Record<string, 2 | 5> = { CRYPTO: 5, FIAT: 2 };
  const [searchparams, setSearchparams] = useSearchParams();

  const [exchangeDetail, setExchangeDetail] =
    React.useState<ExchangeResponse | null>(null);
  const [selected, setSelected] = React.useState<{
    source: Wallet | undefined;
    destination: Wallet | undefined;
  }>({ source: undefined, destination: undefined });
  const [pairsQuery, setPairsQuery] = React.useState<{
    first_currency: string | undefined;
    second_currency: string | undefined;
  }>({ first_currency: undefined, second_currency: undefined });

  const {
    mutateAsync: getAvailableSources,
    data: availableSources,
    isLoading: sourceLoading,
  } = useSwapAvailableSources();
  const {
    mutateAsync: getAvailableDestinations,
    data: availableDestinations,
    isLoading: destinationLoading,
  } = useSwapAvailableDestinations();

  const { data: pairs, remove: removePairs } = usePairs({
    enabled:
      Boolean(pairsQuery.first_currency) && Boolean(pairsQuery.second_currency),
    query: pairsQuery,
  });

  const {
    mutateAsync: fetchRate,
    data: rate,
    isLoading: rateLoading,
  } = useRate();

  const sources =
    availableSources?.map((item) => ({
      value: item.id,
      label: item.currency.title,
    })) || [];

  const destinations =
    availableDestinations?.map((item) => ({
      value: item.id,
      label: item.currency.title,
    })) || [];

  const { mutateAsync: submit, isLoading: submitLoading } = useExchange();
  const { mutateAsync: approve, isLoading: approveLoading } =
    useExchangeApprove();

  const formRequirements = {
    yup: yup.object().shape({
      source_wallet: yup.string().label(t('form.source_wallet')).required(),
      destination_wallet: yup
        .string()
        .label(t('form.destination_wallet'))
        .required(),
      amount: yup.number().label(t('form.amount')).required(),
    }),
    schema: {
      source_wallet: t('form.source_wallet'),
      destination_wallet: t('form.destination_wallet'),
      amount: t('form.amount'),
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    resetField,
  } = useForm({
    resolver: yupResolver(formRequirements.yup),
    defaultValues: {},
  });

  const onSubmit = async (data: PayloadType) => {
    if (rate) {
      submit({
        ...data,
        destination_amount: rate?.rate * data.amount,
        rate: rate?.rate,
      }).then((res) => {
        if (res.id) {
          setExchangeDetail(res);
          onOpen();
        }
      });
    }
  };

  const formProps = useInputAttributes(
    register,
    errors,
    formRequirements.schema
  );

  const getDestinationListHandler = ({
    source_wallet,
  }: {
    source_wallet: string;
  }) =>
    getAvailableDestinations({ source_wallet }).then((res) => {
      setValue('destination_wallet', res[0].id);
      setSelected((prev) => ({ ...prev, destination: res[0] }));
    });

  const getSourceListHandler = () => {
    getAvailableSources({}).then((sourcesResult) => {
      if (sourcesResult.length > 0) {
        setSelected((prev) => ({
          ...prev,
          source:
            sourcesResult.find(
              (item) => item.id === searchparams.get('wallet') || ''
            ) || sourcesResult[0],
        }));
        getDestinationListHandler({
          source_wallet: searchparams.get('wallet') || sourcesResult[0].id,
        });
      }
    });
  };

  React.useEffect(() => {
    getSourceListHandler();
  }, []);

  React.useEffect(() => {
    setValue(
      'source_wallet',
      searchparams.get('wallet') || availableSources?.[0].id || ''
    );
  }, [availableSources]);

  React.useEffect(() => {
    if (selected?.source?.id && selected?.destination?.id) {
      setPairsQuery({
        first_currency: selected?.source?.currency.id,
        second_currency: selected?.destination?.currency.id,
      });
    }
  }, [selected?.source?.id, selected?.destination?.id]);

  React.useEffect(() => {
    if (
      selected?.source?.currency &&
      selected?.destination?.currency &&
      pairs?.list?.[0]?.transaction_methods?.[0].key
    ) {
      fetchRate({
        source_symbol: selected?.source?.currency.symbol,
        destination_symbol: selected?.destination?.currency.symbol,
        transaction_method_key: pairs?.list?.[0]?.transaction_methods?.[0]
          .key as TransactionMethodKey,
      });
    }
  }, [
    selected?.source?.id,
    selected?.destination?.id,
    pairs?.list?.[0]?.transaction_methods?.[0].key,
  ]);

  const sourceWalletAvailableAmount =
    (selected?.source?.amount || 0) - (selected?.source?.frozen_amount || 0);
  const destinationWalletAvailableAmount =
    (selected?.destination?.amount || 0) -
    (selected?.destination?.frozen_amount || 0);

  return (
    <Box w="100%">
      <Box bgColor="dark" my={{ base: 4, xl: 8 }} w="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDir={{ base: 'column', xl: 'row' }}
            w="100%"
            gap={4}
          >
            <Box
              display="flex"
              flexDir="column"
              w={{ base: '100%', xl: '50%' }}
              p={4}
            >
              <Box w="100%" pos="relative">
                <Input
                  {...formProps.source_wallet}
                  isSelectBox
                  options={sources}
                  bgColor="lightPrimary"
                  border="none"
                  borderRadius={5}
                  fontSize="2xl"
                  fontWeight="500"
                  placeholder=""
                  isLoading={sourceLoading}
                  _placeholder={{ color: 'grey' }}
                  onChange={(e) => {
                    if (e.target.value) {
                      setSelected((prev) => ({
                        ...prev,
                        source: availableSources?.find(
                          (item) => e.target.value === item.id
                        ) as unknown as Wallet,
                      }));
                      getDestinationListHandler({
                        source_wallet: e.target.value,
                      });
                    } else {
                      setSelected((prev) => ({ ...prev, source: undefined }));
                    }
                    setSelected((prev) => ({
                      ...prev,
                      destination: undefined,
                    }));
                    removePairs();
                    resetField('amount');
                    resetField('destination_wallet');
                    searchparams.delete('wallet');
                    setSearchparams(searchparams, { replace: true });
                  }}
                />
                <Text pos="absolute" right={2} bottom={5}>
                  {selected.source
                    ? `${fixDecimal({
                        value: sourceWalletAvailableAmount,
                        fractionDigits: decimal[selected?.source?.type],
                      })} ${selected?.source?.currency.symbol}`
                    : ''}
                </Text>
              </Box>
              <Box w="100%">
                <Input
                  {...formProps.amount}
                  label=""
                  type="number"
                  min="0"
                  max="1000000000"
                  step="0.00001"
                  bgColor="lightPrimary"
                  border="none"
                  borderRadius={5}
                  py={20}
                  px={10}
                  fontSize="4xl"
                  fontWeight="500"
                  _placeholder={{ color: 'grey' }}
                />
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-around"
                w="100%"
              >
                <PercentSelect
                  list={[25, 50, 75, 100]}
                  onPercent={(item) =>
                    selected.source
                      ? setValue(
                          'amount',
                          parseFloat(
                            fixDecimal({
                              value: (sourceWalletAvailableAmount * item) / 100,
                              fractionDigits: decimal[selected?.source?.type],
                            })
                          )
                        )
                      : ''
                  }
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDir="column"
              w={{ base: '100%', xl: '50%' }}
              p={4}
            >
              <Box w="100%" pos="relative">
                <Input
                  {...formProps.destination_wallet}
                  isSelectBox
                  options={destinations}
                  bgColor="lightPrimary"
                  border="none"
                  borderRadius={5}
                  fontSize="2xl"
                  fontWeight="500"
                  placeholder=""
                  isLoading={destinationLoading}
                  _placeholder={{ color: 'grey' }}
                  onChange={(e) => {
                    if (e.target.value) {
                      removePairs();
                      setSelected((prev) => ({
                        ...prev,
                        destination: availableDestinations?.find(
                          (item) => item.id === e.target.value
                        ) as unknown as Wallet,
                      }));
                    } else {
                      setSelected((prev) => ({
                        ...prev,
                        destination: undefined,
                      }));
                    }
                  }}
                />
                <Text pos="absolute" right={2} bottom={5}>
                  {selected?.destination
                    ? `${fixDecimal({
                        value: destinationWalletAvailableAmount,
                        fractionDigits: decimal[selected?.destination?.type],
                      })} ${selected?.destination?.currency.symbol}`
                    : ''}
                </Text>
              </Box>
              <Box w="100%">
                <Input
                  label=""
                  name=""
                  bgColor="lightPrimary"
                  border="none"
                  borderRadius={5}
                  py={20}
                  px={10}
                  fontSize="4xl"
                  fontWeight="500"
                  _placeholder={{ color: 'grey' }}
                  isDisabled={true}
                  value={
                    selected?.destination &&
                    watch('amount') &&
                    watch('amount') <= 100000000000 &&
                    rate
                      ? fixDecimal({
                          value: rate?.rate * watch('amount'),
                          fractionDigits: decimal[selected?.destination?.type],
                        })
                      : ''
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            py={4}
            px={4}
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'center', md: 'unset' }}
            w="100%"
          >
            <Text color="primary">Current Rate: </Text>
            {selected?.source && selected?.destination && (
              <Box minW={20} textAlign="center" ml={2}>
                {rateLoading ? (
                  <Spinner size="sm" thickness="2px" />
                ) : (
                  <HStack>
                    <Text>{`1 ${selected?.source?.currency.symbol} =`}</Text>
                    {rate?.rate && (
                      <Text>{`${fixDecimal({
                        value: rate?.rate,
                        fractionDigits: 5,
                      })} ${selected?.destination?.currency.symbol}`}</Text>
                    )}
                  </HStack>
                )}
              </Box>
            )}
          </Box>
          <Box py={4} px={4} w={{ base: '100%', md: '50%' }}>
            <Button
              w="100%"
              variant="filled"
              title={
                watch('amount') > sourceWalletAvailableAmount
                  ? t('insufficientBalance')
                  : t('exchangeSubmit')
              }
              type="submit"
              isDisabled={watch('amount') > sourceWalletAvailableAmount}
              isLoading={submitLoading}
            />
          </Box>
        </form>
      </Box>
      <ApproveModal
        data={{
          amount: watch('amount'),
          destination_amount: parseFloat(
            fixDecimal({
              value: (rate?.rate || 1) * watch('amount'),
              fractionDigits: decimal[selected?.destination?.type || 'CRYPTO'],
            })
          ),
          from: selected?.source?.currency.symbol || '',
          to: selected?.destination?.currency.symbol || '',
          rate: parseFloat(
            fixDecimal({ value: rate?.rate || 1, fractionDigits: 5 })
          ),
        }}
        onClose={onClose}
        isOpen={isOpen}
        loading={approveLoading}
        onButton={() => {
          approve({ id: exchangeDetail?.id || '' }).then(() => {
            setSelected({ source: undefined, destination: undefined });
            setExchangeDetail(null);
            getSourceListHandler();
            reset();
          });
        }}
      />
    </Box>
  );
};

export default SimpleExchange;
